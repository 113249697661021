import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../API/API";

const initialState = {
  buttonData: [],
  isLoading: false,
  error: null,
};

export const fetchBetButton = createAsyncThunk(
  "buttonData/fetch",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      let result = await API.get(
        `/Get_Button_value_List?uid=${id}`,

        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("betbutton", result);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const betButtonSlice = createSlice({
  name: "betButton",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBetButton.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBetButton.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(fetchBetButton.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.buttonData = action.payload;
      });
  },
});

export default betButtonSlice.reducer;
