import DOMPurify from "dompurify";
import { useState } from "react";
import { API } from "../../API/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Authenticate,
  loginUser,
  logoutUser,
} from "../../Redux/Slices/authSlice";

const DataModal = ({
  onClose,
  setNewPswd,
  setTxnPswd,
  newpswd,
  Txnpswd,
  data,
  setInfoModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("data",data)
  const [oldpswd, setoldPswd] = useState("");
  const [cnfpswd, setcnfPswd] = useState("");
  const [count, setCount] = useState(0);
  const { resultid, token, resultusername } = data;

  const handleOldpassword = (event) => {
    const value = DOMPurify.sanitize(event.target.value.substring(0, 20));
    setoldPswd(value);
  };
  const handleNewPassword = (event) => {
    const value = DOMPurify.sanitize(event.target.value.substring(0, 20));
    setNewPswd(value);
  };
  const handleCnfPassword = (event) => {
    const value = DOMPurify.sanitize(event.target.value.substring(0, 20));
    setcnfPswd(value);
  };
  const handleTxnPassword = (event) => {
    const value = DOMPurify.sanitize(event.target.value.substring(0, 20));
    setTxnPswd(value);
  };

  const handleFormSubmit = async () => {
    if (newpswd !== cnfpswd) {
      toast.error("Password && Confirm Password does not match !!");
      return;
    }
    onClose();
    let responceRight;

    try {
      responceRight = await API?.post(
        "/Update_passFirstTime",
        {
          uid: resultid,
          old_password: oldpswd,
          new_password: newpswd,
          trans_password: Txnpswd,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      let res = responceRight.data.data.result;
      console.log("firstTime", responceRight.data);
      if (res == "Update Successfull") {
        setInfoModal(true);
        // toast.success(res);
        toast.success("Update Successfull");
        // let userdata = user.data.resultid;
        // let token = user.token;
        // let username = user.data.resultusername;
        // console.log("token", token);
        dispatch(Authenticate());
        sessionStorage.setItem("token", token);

        sessionStorage.setItem("userData", resultid);
        sessionStorage.setItem("userName", resultusername);
      } else {
        toast.error(res);
        dispatch(logoutUser());
        setNewPswd("");
        setTxnPswd("");
      }
    } catch (e) {
      toast.error(e.message);
      console.log("Error While calling Myteam API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(loginUser());

        navigate("/");
      }
    }
  };
  return (
    <>
      <div
        onClick={() => {
          onClose();
        }}
        className="login-modal-wrapper"
      ></div>
      <div className="login-modal-container">
        <div className="head-border d-flex align-items-center">
          <p className="my-auto mx-2">Update Password</p>
        </div>
        <div className="container-body">
          <div className="row">
            <div className="col-6">
              <p className="mx-2 ">Old Password</p>
            </div>
            <div className="col-6">
              <input
                className="modal-w-btn"
                type="password"
                value={oldpswd}
                onChange={(e) => handleOldpassword(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="mx-2 ">New Password</p>
            </div>
            <div className="col-6">
              <input
                className="modal-w-btn"
                type="password"
                value={newpswd}
                onChange={(e) => handleNewPassword(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="mx-2 ">Confirm Password</p>
            </div>
            <div className="col-6">
              <input
                className="modal-w-btn"
                type="password"
                value={cnfpswd}
                onChange={(e) => handleCnfPassword(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="mx-2 ">Transaction Password</p>
            </div>
            <div className="col-6">
              <input
                className="modal-w-btn"
                type="password"
                value={Txnpswd}
                onChange={(e) => handleTxnPassword(e)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer row mt-1">
          <div className="col-5"></div>
          <div className="col-7 py-2">
            <button
              onClick={() => {
                onClose();
              }}
              className="mcb "
            >
              Cancel
            </button>
            <button className="mbb mx-1" onClick={handleFormSubmit}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataModal;
