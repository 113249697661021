import axios from 'axios';
import { API } from '../../API/API';

const placeBetAPI = axios.create();

export const placeBetColorBall = async (data, token) => {
  try {
    const response = await API.post('/placebetcolorball', data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default placeBetAPI;
