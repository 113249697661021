// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import apiReducer from '../Slices/apiSlices';
import authReducer from "../Slices/authSlice";
import ipReducer from "../Slices/ipSlice";

import betHistoryReducer from "../Slices/betHistorySlice";
import walletLoginReducer from "../Slices/walletLoginSlice";
import accountStatementReducer from "../Slices/accountStatementSlice";
import lossProfitReducer from "../Slices/lossProfitSlice";
import updatePassReducer from "../Slices/updatePassSlice";
import placeColorBetReducer from "../Slices/placeColorBetSlice";
import fetchMatchNameReducer from "../Slices/matchNameSlice";
import betaWalletReducer from "../Slices/betaWalletSlice";
import betStackReducer from "../Slices/betStackSlice";
import calculateProfitReducer from "../Slices/calculateProfitSlice";
import userBalanceReducer from "../Slices/userBalanceSlice";
import withdrawHistoryReducer from "../Slices/withdrawHistorySlice";
import depositHistoryReducer from "../Slices/depositHistorySlice";
import unsellectedBetReducer from "../Slices/unsellectedBetSlice";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import sessionStackReducer from "../Slices/sessionStackSlice";
import LastbetReducer from "../Slices/LastbetSlice";
import dashbetHistoryReducer from "../Slices/dashbetHistorySlice";
import SaveTeamsReducer from "../Slices/SaveTeamsSlice";
import competitionReducer from "../Slices/competitionSlice";
import IframeReducer from "../Slices/IframeSlice";
import casinobetReducer from "../Slices/casinobetSlice";
import casinobetHistoryReducer from "../Slices/casinobetHistory";
import rulesReducer from "../Slices/rulesSlice";
import competionwiseReducer from "../Slices/compititionwisematch";
import { AuthInterpertor } from "../middleware/authMiddleware";
import thirtyTwoCardGameReducer from "../Slices/thirtyTwoCardGameSlice";
import resultSchedulerReducer from "../Slices/schedulerSlice";
import  fetchBetButtonreducer  from "../Slices/betButtonSlice";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  ip: ipReducer,
  betHistory: betHistoryReducer,
  walletLogin: walletLoginReducer,
  accountStatement: accountStatementReducer,
  lossProfit: lossProfitReducer,
  updatePass: updatePassReducer,
  placeBet: placeColorBetReducer,
  depositHistory: depositHistoryReducer,
  matchInfo: fetchMatchNameReducer,
  unsellectedBet: unsellectedBetReducer,
  betawallet: betaWalletReducer,
  betStack: betStackReducer,
  userProfit: calculateProfitReducer,
  userBalanceData: userBalanceReducer,
  withdrawHistory: withdrawHistoryReducer,
  lastbetDetails: LastbetReducer,
  sessionStack: sessionStackReducer,
  dashbetHistory: dashbetHistoryReducer,
  saveTeams: SaveTeamsReducer,
  iframeDetails: IframeReducer,
  casinobet: casinobetReducer,
  casinobetHistory: casinobetHistoryReducer,
  competition: competitionReducer,
  rulesDetails: rulesReducer,
  competitionwise: competionwiseReducer,
  thirtyTwoCardGameStack: thirtyTwoCardGameReducer,
  casinoResultScheduler: resultSchedulerReducer,
  buttonData:fetchBetButtonreducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([AuthInterpertor]),
});

export const persistor = persistStore(store);
