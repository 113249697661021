import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../API/API";

const userName = sessionStorage.getItem("userName");
const token = sessionStorage.getItem("token");
export const competionDetails = createAsyncThunk('match/competition', async ({ eventId }, { rejectWithValue }) => {

  // console.log("comp_slice",eventId)
  try {

    const response = await API.get(`GetAllCompetitionsData?EventTypeID=${eventId}&username=${userName}`,
      null,
      {
        headers: {
          Authorization: token,
        },
      });
    // console.log("comp_res",response.data.data)
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const competitionSlice = createSlice({
  name: 'competition',
  initialState: {
    error: null,
    competitions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(competionDetails.fulfilled, (state, action) => {
        state.error = null;
        state.competitions = action.payload;
      })
      .addCase(competionDetails.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Unknown error";
      });
  },
});

export default competitionSlice.reducer;
