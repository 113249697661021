import { API } from '../../API/API';



export const getAccountStatement = async (data, token) => {
  try {
    const response = await API.post('/getAccount_Statement', data, {
      headers: {
        Authorization: token,
      },
    });
    console.log("Account", response);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
