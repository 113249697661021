import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../Redux/Slices/authSlice";

const InternetStatusChecker = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.dismiss();
      // console.log("You are online");
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.error("You are offline");
      // console.log("You are offline");
      dispatch(logoutUser());
      sessionStorage.clear();
      navigate("/Login");
    };

    const handleSlowNetwork = () => {
      setIsOnline(false);
      toast.error("Your network connection is slow. Please login again.");
      console.log("Slow network detected");
      dispatch(logoutUser());
      sessionStorage.clear();
      navigate("/Login");
    };

    const handleNetworkChange = () => {
      // console.log("Network change detected", navigator.connection);
      if (
        navigator.connection &&
        ["slow-2g", "2g"].includes(navigator.connection.effectiveType)
      ) {
        handleSlowNetwork();
      } else if (window.navigator.onLine) {
        handleOnline();
      } else {
        handleOffline();
      }
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    if (navigator.connection) {
      navigator.connection.addEventListener("change", handleNetworkChange);
    }

    handleNetworkChange();

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", handleNetworkChange);
      }
    };
  }, [navigate, dispatch]);

  return null;
};

export default InternetStatusChecker;
