import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../API/API";

const userName = sessionStorage.getItem("userName");
const token = sessionStorage.getItem("token");
export const competionwisematchDetails = createAsyncThunk(
  "match/competitionwise",
  async ({ competitionId, eventId }, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `getMatchCompititionWise?CompetitionID=${competitionId}&eventTypeid=${eventId}&username=${userName}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const competitionwiseSlice = createSlice({
  name: "competitionwise",
  initialState: {
    error: null,
    matchs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(competionwisematchDetails.fulfilled, (state, action) => {
        state.error = null;
        state.matchs = action.payload;
      })
      .addCase(competionwisematchDetails.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Unknown error";
      });
  },
});

export default competitionwiseSlice.reducer;
