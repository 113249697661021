import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../API/API";

export const fetchuserCashout = createAsyncThunk(
  "user/cashOut",
  async ({ userId, id, token, liability, cashOut1, balance },{rejectWithValue}) => {
    try {
      const response = await API.post(
        "/CashoutManage",
        {
          uid: userId,
          matchid: id,
          libility: liability,
          CashOut: cashOut1,
          balance: balance,
          username: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("CashOut Res", response);
      return response.data.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const cashOutSlice = createSlice({
  name: "cashOut",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchuserCashout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchuserCashout.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchuserCashout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default cashOutSlice.reducer;
