import axios from 'axios';
import { API } from '../../API/API';


const walletLoginAPI = axios.create();

export const loginWithWallet = async (address) => {
  try {
    const response = await API.post('/login_WithWallet', { address });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default walletLoginAPI;
