import Web3 from "web3";
let isItConnected = false;
const networks = {
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance smart chain",
    nativeCurrency: {
      name: "BSC",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
};
const changeNetwork = async ({ networkName }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    const web3 = window.web3;
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(56) }]
    });
    window.location.reload();
  } catch (err) {
    console.log(err, "not found");
  }
};
const handleNetworkSwitch = async (networkName) => {  
  await changeNetwork({ networkName });
};
let accounts;
const getAccounts = async () => {
  const web3 = window.web3;
  try {
    accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (error) {
    console.log("Error while fetching acounts: ", error);
    return null;
  }
};
export const disconnectWallet = async () => {
  await window.ethereum.request({
    method: "eth_requestAccounts",
    params: [{ eth_accounts: {} }],
  });
  console.log("disconnect");
};

// export const loadWeb3 = async () => {
//   try {
//     if (window.ethereum) {
//       window.web3 = new Web3(window.ethereum);
//       await window.ethereum.enable();
//       await window.web3.eth.getChainId((err, netId) => {
//          console.log("networkId==>", netId);
//         switch (netId.toString()) {
//           case "56":
//             isItConnected = true;
//             break;
//           default:
//             handleNetworkSwitch("bsc");
//             isItConnected = false;
//         }
//       });
//       if (isItConnected == true) {
//         let accounts = await getAccounts();
//         return accounts[0];
//       } else {
//         let res = "Wrong Network";
//         return res;
//       }
//     } else {
//       let res = "No Wallet";
//       return res;
//     }
//   } catch (error) {
//     let res = "No Wallet";
//     return res;
//   }
// };



export const loadWeb3 = async () => {
  let isItConnected = false;

  try {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const netId = await window.web3.eth.getChainId();
      //console.log("networkId==>", netId.toString());

      const networkMap = {
        "56": "bsc",
        // Add more network IDs and their corresponding names here
      };

      const networkName = networkMap[netId.toString()];
      if (networkName === "bsc") {
        isItConnected = true;
      } else {
        handleNetworkSwitch(networkName);
      }

      if (isItConnected) {
        const accounts = await getAccounts();
        return accounts[0];
      } else {
        return "Wrong Network";
      }
    } else {
      return "No Wallet";
    }
  } catch (error) {
    console.error("Error loading Web3:", error);
    return "Error";
  }
};
