// matchNameAPI.js
import axios from "axios";
import { API } from "../../API/API";


export const getMatchName = async (id, token) => {
  try {
    const response = await API.get(
      `GetAllCurrentMatches?eventTypeID=${id}&fromDate=" "&toDate=" "&searchByName=" "`
    );

    // console.log("matchDetails",response.data.data)
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default getMatchName;
