import a from "../../Media/img1.png";
import e from "../../Media/img5.png";
import b from "../../Media/img2.png";
import c from "../../Media/32CardsB.webp";
import d from "../../Media/img4.png";
const MiancardData = [
    {   id:1,
        title:"20-20 TEEN PATTI",
        imgsrc:a,
        link: "/TeenPatti/4"
        
    },
    {   id:2,
        title:"32 CARD A",
        imgsrc:b,
        link: "/32Cards/1"
        
        
    },
    // {   id:3,
    //     title:"32 CARD",
    //     imgsrc:c,
    //     link: "/32Cards/1"
        
       
    // },
    {   id:4,
        title :"DRAGON TIGER 20-20",
        imgsrc:d,
        link: "/DragonTiger/5"
        
        
    },
    {   id:5,
        title :"LUCKY 7A",
        imgsrc:e,
        link: "/Lucky7A/2"
        

    }
];
export default MiancardData;