import React from "react";
import LiveTvSection from "../SportNew/LiveTvSection";
import FootballOdds from "./FootballOdds";
import ScoreFootball from "./ScoreFootball";
import ScoreCard from "../SportNew/ScoreCard";

export default function FootballSports() {
  return (
    <div className="col-lg-10  pt-1  ">
      <div className="row px-1">
        <div className="col-lg-8  px-2 score-card">
          <ScoreCard />
          <FootballOdds />
        </div>
        <div className="col-4">
          <LiveTvSection />
        </div>
      </div>
    </div>
  );
}
