import React from 'react'
import ScoreFootball from '../Sports Football/ScoreFootball';
import TennisOdds from './TennisOdds';
import LiveTvSection from '../SportNew/LiveTvSection';
import ScoreCard from '../SportNew/ScoreCard';

export default function TennisSport() {
    return (
      <div className="col-lg-10  pt-1  ">
        <div className="row px-1">
          <div className="col-lg-8  px-2 score-card">
            <ScoreCard />
            <TennisOdds />
          </div>
          <div className="col-4">
            <LiveTvSection />
          </div>
        </div>
      </div>
    );
  }
