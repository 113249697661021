import React, { useEffect, useState } from "react";
import "./FundsTransfer.css";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";

export default function FrenFundConvert() { 
  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  let uId = sessionStorage.getItem("userName");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [touid, settouid] = useState("");
  const [transPassword, settransPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [netbal, setNetbal] = useState("");
  const [walletbalance, setwalletbalance] = useState("");

  const DashboardValues = async () => {
    try {
      let resnetbal = await API.post(
        `/getbetawallet`,
        { username: uId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Live_Rate1", resnetbal);
      setwalletbalance(resnetbal.data.data[0][0].FranchiseeWalletBal);
    } catch (e) {
      console.log("Error While Fatch Dashboard API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };
  const hendelClick = (event) => {
    let value = event.target.value;
    let sanitizedValue = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
    sanitizedValue = sanitizedValue.replace(/[^0-9a-zA-Z]/g, "");
    settouid(sanitizedValue);
    Check_Sponser(sanitizedValue);
  };

  const [sponserCheck, setSponserCheck] = useState(null);
  const Check_Sponser = async (value) => {
    // let value1 = value ?? token;
    try {
      let res = await API.post(
        `/checkValidId`,
        {
          uid: value,
          UserType: "User",
          result: "",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Check_Sponser", res);
      res = res.data.data;
      setSponserCheck(res);
    } catch (e) {
      console.log("Somthing Error in Sponser API");
    }
  };

  useEffect(() => {
    DashboardValues();
  }, []);

  const converttowallet = async () => {
    console.log("");
    if (amount > walletbalance) {
      toast.error("Insufficient Wallet Ballance");
      return;
    }

    try {
      let res = await API.post(
        `ConvertFranToGameWallet`,
        {
          UserId: touid,
          FromUserId: uId,
          Amount: amount,
          Password:transPassword,
          username: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Even_Type_Fund", res);
      if (res.data.data.Result === "Success") {
        toast.success("Fund converted Successfully !!");
        setAmount("");
        settransPassword("");
        settouid("");
        setSponserCheck("");
        DashboardValues();
      } else {
        toast.error(res.data.data.Result);
      }
    } catch (error) {
      console.log("Something Error in handleUpdatePassword API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  // useEffect(() => {
  //   Live_Rate();
  // }, []);

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 px-2 mt-1">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Franchisee Fund Convert To Game Wallet</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card f-card mt-4 py-2 col-lg-8">
                <div className="px-4">
                  {/* <form> */}
                  <div className="form-group">
                    <label className="" htmlFor="exampleInputEmail1">
                      {" "}
                      Franchisee Wallet Balance
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2 user-id-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Withdrawal Wallet Balance"
                      value={walletbalance}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label className="" htmlFor="exampleInputEmail1">
                      {" "}
                      User ID
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2 user-id-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter  User ID"
                      value={userId}
                      disabled
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Amount To be Transfer
                    </label>

                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) =>
                        setAmount(
                          DOMPurify.sanitize(
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        )
                      }
                      maxLength={10}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Transfer User Id
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Enter User ID"
                      value={touid}
                      onChange={(e) => hendelClick(e)}
                      maxLength={10}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Transfer User Name
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Transfer User Name"
                      value={sponserCheck}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Transaction Password
                    </label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Enter Transaction Password"
                      onChange={(e) =>
                        settransPassword(
                          DOMPurify.sanitize(e.target.value.substring(0, 20))
                        )
                      }
                      value={transPassword}
                      maxLength={20}
                    />
                  </div>

                  <button
                    // type="submit"
                    className="btn transfer-btn mt-4"
                    onClick={() => converttowallet()}>
                    Transfer
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};