import React from "react";
import "./LiveCasino.css";
import { NavLink } from "react-router-dom";
import games from "../casino_game.json";

import h from "../../Media/American-Roulette.png";

const LiveCasino = () => {
  // const [games, setGames] = useState([]);

  // useEffect(() => {
  //   const fetchGames = async () => {
  //     try {
  //       const response = await API.get("/MasterGame?type=1");
  //       // console.log("Response data:", response.data.data[0]);
  //       setGames(response.data.data[0]);
  //     } catch (error) {
  //       console.error("Error fetching games", error);
  //     }
  //   };

  //   fetchGames();
  // }, []);

  return (
    <div className="col-lg-10 ">
      <div className="row">
        <div className="col-12 live-casino-main-div p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Live Tables</h4>
            <div className="d-flex flex-wrap flex-row justify-content-between align-items-center px-2 py-1">
              {games?.map((list) => (
                <NavLink
                  key={list.id}
                  to={`${list.Route}`}
                  className="nav-link"
                >
                  <div className="">
                    <div className="card casinocard-div mx-auto ms-1 mt-3">
                      <img
                        className="small-card-img"
                        src={list.images}
                        alt={list.name}
                        style={{ color: "white" }}
                      />
                      <div className="overlaySmallCard d-flex justify-content-center">
                        <p className="">{list.name}</p>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))}
            
              {/* <NavLink to="/Roulette" className="nav-link">
                <div className="">
                  <div className="card casinocard-div  ms-1 mt-3">
                    <img
                      className="small-card-img"
                      src={h}
                      alt="Color Game"
                      style={{ color: "white" }}
                    />
                    <div className="overlaySmallCard d-flex justify-content-center">
                      <p className="">Roulette</p>
                    </div>
                  </div>
                </div>
              </NavLink> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveCasino;
