import axios from "axios";
import { API } from "../../API/API";


const betHistoryAPI = axios.create();

export const fetchBetHistory = async (data, token) => {
  try {
    const response = await API.post('BetHistory', data, {
      headers: {
        Authorization: token,
      },
    });
    console.log("bethistroy", response );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default betHistoryAPI;
