import axios from "axios";
import { API } from "../../API/API";

const depositHistoryAPI = axios.create();

export const fetchUserDepositHistory = async (id, token) => {
  try {
    const response = await API.get(`buycoin_history?id=${id}`, {
      headers: {
        Authorization: token,
      },
    });
    console.log("userdepositHistory", response.data.data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default depositHistoryAPI;
