import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { updateCurrentMatch } from "../../Redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";

const MatchData = ({ data, activeIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(updateCurrentMatch(data));
    if (activeIndex == 1) {
      navigate(`/Football/${data?.id}`);
    } else if (activeIndex == 2) {
      navigate(`/Tennis/${data?.id}`);
    } else if (activeIndex == 4) {
      navigate(`/Sport/${data?.id}`);
    }
  };
  return (
    <div className="row match-div">
      <div className="col-lg-8 ">
        {/* <Link to={`/Sport/${data?.MarketID}/${data?.id}`}> */}
        <div
          className=" d-flex justify-content-between "
          onClick={() => handleClick()}
        >
          <div className="d-flex">
            <div className="schedule-btn ms-2 mt-2 pt-2 d-flex flex-column justify-content-center align-items-center">
              {/* {moment(data.openDate)
                .tz("Asia/Kolkata")
                .format("DD/MM/YYYY h:m A")} */}
              {data.ldate1}
              {/* Tommorow
              <p>4:14 PM</p> */}
            </div>
            <div className="ms-3">
              <h6 className="match-name mt-2 mb-0">
                {/* ENGLAND U19 <span className="vsd">V</span> ZImbabwew U19 */}
                {data?.name}
              </h6>
              <span className="tournamnet-name mt-0">{data?.ComName}</span>
            </div>
          </div>
          {/* <div>
            <button className="b1-btn">B 1</button>
            <button className="f9-btn">F 9</button>
          </div> */}
        </div>
        {/* </Link> */}
      </div>

      <div className="col-lg-4 order-3">
        <div className="row">
          <div className="col-4">
            <div className="mt-3">
              <button className="blue-btn ">{data?.Back_1}</button>
              <button className=" ms-2 orange-btn">{data?.Lay_1}</button>
            </div>
          </div>
          <div className="col-4">
            <div className="mt-3">
              <button className="blue-btn ">{data?.Back_x}</button>
              <button className=" ms-2 orange-btn">{data?.Lay_x}</button>
            </div>
          </div>
          <div className="col-4">
            <div className="mt-3">
              <button className="blue-btn ">{data?.Back_2}</button>
              <button className=" ms-2 orange-btn">{data?.Lay_2}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MatchData);
