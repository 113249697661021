import axios from "axios";
import { API } from "../../API/API";

const updatePassAPI = axios.create();

export const updatePassword = async (data, token) => {
  try {
    const response = await API.post("/Update_pass", data, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default updatePassAPI;
